import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vueVimeoPlayer from "vue-vimeo-player";
import "./vee-validate";
import "./registerServiceWorker";
window.EventBus = new Vue();
Vue.config.productionTip = false;
Vue.use(vueVimeoPlayer);
import { ValidationObserver } from "vee-validate";
Vue.component("ValidationObserver", ValidationObserver);
import { ValidationProvider } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
import FcTextLeft from "@/components/text/FcTextLeft";
Vue.component("fc-text-left", FcTextLeft);
import TaSteps from "@/components/text/TaSteps";
Vue.component("ta-steps", TaSteps);
import taDividerOne from "@/components/core/taDividerOne";
Vue.component("ta-divider-one", taDividerOne);
import TaBtnCont from "@/components/buttons/taBtnCont";
Vue.component("ta-btn-cont", TaBtnCont);
import TaBtnBack from "@/components/buttons/taBtnBack";
Vue.component("ta-btn-back", TaBtnBack);
import taBtnYes from "@/components/buttons/taBtnYes";
Vue.component("ta-btn-yes", taBtnYes);
import taBtnNo from "@/components/buttons/taBtnNo";
Vue.component("ta-btn-no", taBtnNo);
import taBtnNewCode from "@/components/buttons/taBtnNewCode";
Vue.component("ta-btn-new-code", taBtnNewCode);
import TaArea from "@/components/form/TaArea";
Vue.component("ta-area", TaArea);
import TaValArea from "@/components/form/TaValArea";
Vue.component("ta-val-area", TaValArea);
import TaErrorForm from "@/components/form/FcErrorForm";
Vue.component("fc-error-form", TaErrorForm);
import TaCheckBox from "@/components/form/TaCheckBox";
Vue.component("ta-checkbox", TaCheckBox);
import TaRadio from "@/components/form/TaRadio";
Vue.component("ta-radio", TaRadio);
import TaValInput from "@/components/form/TaValInput";
Vue.component("ta-val-input", TaValInput);
import TaInput from "@/components/form/TaInput";
Vue.component("ta-input", TaInput);
import FcStatements from "@/components/text/FcStatements";
Vue.component("fc-statement", FcStatements);
import FcNoteModal from "@/components/text/FcNoteModal";
Vue.component("fc-note-modal", FcNoteModal);
import TaSliderFeeling from "@/components/form/TaSliderFeeling";
Vue.component("ta-slider-feeling", TaSliderFeeling);
import FcstopElement from "@/components/buttons/FcstopElement";
Vue.component("fc-stop-element", FcstopElement);
import FcButtonStop from "@/components/buttons/FcButtonStop";
Vue.component("fc-button-stop", FcButtonStop);
import FcEndElement from "@/components/core/FcEndElement";
Vue.component("fc-end-element", FcEndElement);
import FcBtnBackBegin from "@/components/buttons/FcBtnBackBegin";
Vue.component("fc-button-back-begin", FcBtnBackBegin);
import FcLogoutModal from "@/components/core/FcLogoutModal";
Vue.component("fc-logout-modal", FcLogoutModal);
import TaLeftIntroScreen from "@/components/core/TaLeftIntroScreen";
Vue.component("ta-left-intro", TaLeftIntroScreen);
import TaStatusIcon from "@/components/core/TaStatusIcon";
Vue.component("ta-status-icon", TaStatusIcon);
import TaModuleCard from "@/components/core/TaModuleCard";
Vue.component("ta-module-card", TaModuleCard);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
