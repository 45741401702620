import ResultService from "@/services/ResultService";
import { getError } from "@/utils/helpers";
export const namespaced = true;

export const state = {
  loading_data: false,
  error_data: null,
  email: localStorage.getItem("email") || "",
  step: 0,
  sl_a: 0,
  sl_b: 0,
  sl_c: 0,
  sl_d: 0,
  sl_e: 0,
  sl_f: 0,
  sl_g: 0,
  sl_h: 0,
  sl_i: 0,
  sl_j: 0,
  sl_k: 0,
  sl_l: 0,
  sl_m: 0,
  sl_n: 0,
  sl_o: 0,
  sl_p: 0,
  sl_q: 0,
  sl_r: 0,
  sl_s: 0,
  ready_a: false,
  ready_b: false,
  ready_c: false,
  ready_d: false,
  remark_a: "",
  remark_b: "",
  remark_c: "",
  remark_d: "",
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading_data = loading;
  },
  SET_ERROR(state, error) {
    state.error_data = error;
  },

  update_step(state, step) {
    state.step = step;
  },
  update_sl_a(state, sl_a) {
    state.sl_a = sl_a;
  },
  update_sl_b(state, sl_b) {
    state.sl_b = sl_b;
  },
  update_sl_c(state, sl_c) {
    state.sl_c = sl_c;
  },
  update_sl_d(state, sl_d) {
    state.sl_d = sl_d;
  },
  update_sl_e(state, sl_e) {
    state.sl_e = sl_e;
  },
  update_sl_f(state, sl_f) {
    state.sl_f = sl_f;
  },
  update_sl_g(state, sl_g) {
    state.sl_g = sl_g;
  },
  update_sl_h(state, sl_h) {
    state.sl_h = sl_h;
  },
  update_sl_i(state, sl_i) {
    state.sl_i = sl_i;
  },
  update_sl_j(state, sl_j) {
    state.sl_j = sl_j;
  },
  update_sl_k(state, sl_k) {
    state.sl_k = sl_k;
  },
  update_sl_l(state, sl_l) {
    state.sl_l = sl_l;
  },
  update_sl_m(state, sl_m) {
    state.sl_m = sl_m;
  },
  update_sl_n(state, sl_n) {
    state.sl_n = sl_n;
  },
  update_sl_o(state, sl_o) {
    state.sl_o = sl_o;
  },
  update_sl_p(state, sl_p) {
    state.sl_p = sl_p;
  },
  update_sl_q(state, sl_q) {
    state.sl_q = sl_q;
  },
  update_sl_r(state, sl_r) {
    state.sl_r = sl_r;
  },
  update_sl_s(state, sl_s) {
    state.sl_s = sl_s;
  },
  update_ready_a(state, ready_a) {
    state.ready_a = ready_a;
  },
  update_ready_b(state, ready_b) {
    state.ready_b = ready_b;
  },
  update_ready_c(state, ready_c) {
    state.ready_c = ready_c;
  },
  update_ready_d(state, ready_d) {
    state.ready_d = ready_d;
  },
  update_remark_a(state, remark_a) {
    state.remark_a = remark_a;
  },
  update_remark_b(state, remark_b) {
    state.remark_b = remark_b;
  },
  update_remark_c(state, remark_c) {
    state.remark_c = remark_c;
  },
  update_remark_d(state, remark_d) {
    state.remark_d = remark_d;
  },
};

export const getters = {
  step: (state) => {
    return state.step;
  },
  sl_a: (state) => {
    return state.sl_a;
  },
  sl_b: (state) => {
    return state.sl_b;
  },
  sl_c: (state) => {
    return state.sl_c;
  },
  sl_d: (state) => {
    return state.sl_d;
  },
  sl_e: (state) => {
    return state.sl_e;
  },
  sl_f: (state) => {
    return state.sl_f;
  },
  sl_g: (state) => {
    return state.sl_g;
  },
  sl_h: (state) => {
    return state.sl_h;
  },
  sl_i: (state) => {
    return state.sl_i;
  },
  sl_j: (state) => {
    return state.sl_j;
  },
  sl_k: (state) => {
    return state.sl_k;
  },
  sl_l: (state) => {
    return state.sl_l;
  },
  sl_m: (state) => {
    return state.sl_m;
  },
  sl_n: (state) => {
    return state.sl_n;
  },
  sl_o: (state) => {
    return state.sl_o;
  },
  sl_p: (state) => {
    return state.sl_p;
  },
  sl_q: (state) => {
    return state.sl_q;
  },
  sl_r: (state) => {
    return state.sl_r;
  },
  sl_s: (state) => {
    return state.sl_s;
  },
  ready_a: (state) => {
    return state.ready_a;
  },
  ready_b: (state) => {
    return state.ready_b;
  },
  ready_c: (state) => {
    return state.ready_c;
  },
  ready_d: (state) => {
    return state.ready_d;
  },
};

export const actions = {
  async getDataE({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ResultService.getResultE(payload);
      commit("update_step", response.data.data.elightmodule.step);
      commit("update_sl_a", response.data.data.elightmodule.sl_a);
      commit("update_sl_b", response.data.data.elightmodule.sl_b);
      commit("update_sl_c", response.data.data.elightmodule.sl_c);
      commit("update_sl_d", response.data.data.elightmodule.sl_d);
      commit("update_sl_e", response.data.data.elightmodule.sl_e);
      commit("update_sl_f", response.data.data.elightmodule.sl_f);
      commit("update_sl_g", response.data.data.elightmodule.sl_g);
      commit("update_sl_h", response.data.data.elightmodule.sl_h);
      commit("update_sl_i", response.data.data.elightmodule.sl_i);
      commit("update_sl_j", response.data.data.elightmodule.sl_j);
      commit("update_sl_k", response.data.data.elightmodule.sl_k);
      commit("update_sl_l", response.data.data.elightmodule.sl_l);
      commit("update_sl_m", response.data.data.elightmodule.sl_m);
      commit("update_sl_n", response.data.data.elightmodule.sl_n);
      commit("update_sl_o", response.data.data.elightmodule.sl_o);
      commit("update_sl_p", response.data.data.elightmodule.sl_p);
      commit("update_sl_q", response.data.data.elightmodule.sl_q);
      commit("update_sl_r", response.data.data.elightmodule.sl_r);
      commit("update_sl_s", response.data.data.elightmodule.sl_s);
      commit("update_ready_a", response.data.data.elightmodule.ready_a);
      commit("update_ready_b", response.data.data.elightmodule.ready_b);
      commit("update_ready_c", response.data.data.elightmodule.ready_c);
      commit("update_ready_d", response.data.data.elightmodule.ready_d);
      commit("update_remark_a", response.data.data.elightmodule.remark_a);
      commit("update_remark_b", response.data.data.elightmodule.remark_b);
      commit("update_remark_c", response.data.data.elightmodule.remark_c);
      commit("update_remark_d", response.data.data.elightmodule.remark_d);
      commit("SET_LOADING", false);
      commit("SET_ERROR", response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_STEPONE", false);
      commit("SET_ERROR", getError(error));
    }
  },
};
