import { render, staticRenderFns } from "./FcButtonStop.vue?vue&type=template&id=06e2e5e1&scoped=true&"
import script from "./FcButtonStop.vue?vue&type=script&lang=js&"
export * from "./FcButtonStop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e2e5e1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
