import * as API from "@/services/API";
import axios from "axios";

export default {
  async login(payload) {
    return API.apiClient.post("/loginlchart", payload);
  },
  loginB(payload) {
    return API.apiClient.post("/loginlchartb", payload);
  },
  loginFam(payload) {
    return API.apiClient.post("/getInfolChart", payload);
  },
  logout(payload) {
    const token = localStorage.getItem("token");
    return axios.post("/logoutlchart", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getAuthUser() {
    return API.apiClient.get("/api/auth/auth");
  },
  getInfo(payload) {
    return API.apiClient.post("/getInfoChart", payload);
  },
};
