<template>
  <div class="mb-5 mt-5">
    <h4 class="error--text">Er gaat iets mis! Bekijk uw antwoorden.</h4>
  </div>
</template>

<script>
export default {
  name: "TaErrorForm",
};
</script>

<style scoped></style>
