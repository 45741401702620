import Vue from "vue";
import Vuex from "vuex";
import * as auth from "@/store/modules/Auth";
import * as moduleA from "@/store/modules/moduleA";
import * as moduleB from "@/store/modules/moduleB";
import * as moduleC from "@/store/modules/moduleC";
import * as moduleD from "@/store/modules/moduleD";
import * as moduleE from "@/store/modules/moduleE";
import * as moduleF from "@/store/modules/moduleF";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    moduleA,
    moduleB,
    moduleC,
    moduleD,
    moduleE,
    moduleF,
  },
});
